<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="animated fadeIn">
      <b-form @submit.prevent="handlePreSubmit" novalidate>
        <b-card-header>
          Edit User Army
          <div class="card-header-actions">
            <a
              class="card-header-action"
              href="user-army"
              rel="noreferrer noopener"
            >
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
          <b-row class="mb-4">
            <!-- Email User -->
            <b-col class="mb-4" sm="12">
              <h6>Email</h6>
              <b-form-group id="userEmail" label-for="email">
                <b-form-input
                  id="email"
                  type="text"
                  size="md"
                  v-model="form.email"
                  aria-describedby="userEmail"
                  placeholder="Input email user"
                  autocomplete="given-name"
                  readOnly
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <!-- Title -->
            <b-col class="mb-4" sm="12">
              <h6>Title</h6>
              <b-form-group id="title" label-for="title">
                <b-form-input
                  id="title"
                  type="text"
                  size="md"
                  v-model="form.title"
                  aria-describedby="title"
                  placeholder="Input title knight"
                  autocomplete="given-name"
                  readOnly
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <!-- Title -->
            <b-col class="mb-4" sm="12">
              <h6>Registered Date</h6>
              <b-form-group id="registerDate" label-for="registerDate">
                <b-form-input
                  id="registerDate"
                  type="text"
                  size="md"
                  v-model="form.registerDate"
                  aria-describedby="registerDate"
                  placeholder="Input registerDate knight"
                  autocomplete="given-name"
                  readOnly
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <!-- RefCode User -->
            <b-col class="mb-4" sm="12">
              <h6>Referral Code User</h6>
              <b-form-group id="ref-code-user" label-for="ref-code">
                <b-form-input
                  id="ref-code"
                  type="text"
                  size="md"
                  v-model="form.refCode"
                  aria-describedby="ref-code-user"
                  placeholder="Input referral code user"
                  autocomplete="given-name"
                  @input="handleClearError('referral_code_user')"
                  :class="{
                    'is-invalid':
                      $v.form.refCode.$error ||
                      errors.messageInputs.referral_code_user
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="!$v.form.refCode.required && $v.form.refCode.$dirty"
                  >Referreal Code cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs &&
                      errors.messageInputs.referral_code_user
                  "
                >
                  {{ errors.messageInputs.referral_code_user }}
                </span>
              </b-form-group>
            </b-col>
            <!-- Email Chief -->
            <b-col class="mb-4" sm="12">
              <h6>Email Chief</h6>
              <b-form-group id="user-email-chief" label-for="email-chief">
                <b-form-input
                  id="email-chief"
                  type="text"
                  size="md"
                  v-model="form.emailChief"
                  aria-describedby="user-email-chief"
                  placeholder="Input email chief"
                  autocomplete="given-name"
                  @input="handleClearError('email_chief')"
                  :class="{
                    'is-invalid':
                      $v.form.emailChief.$error ||
                      errors.messageInputs.email_chief
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.emailChief.required && $v.form.emailChief.$dirty
                  "
                  >Email Chief cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.emailChief.validEmail && $v.form.emailChief.$dirty
                  "
                  >Email Chief must be valid</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs && errors.messageInputs.email_chief
                  "
                >
                  {{ errors.messageInputs.email_chief }}
                </span>
              </b-form-group>
            </b-col>
            <!-- RefCode Chief -->
            <b-col class="mb-4" sm="12">
              <h6>Referral Code Chief</h6>
              <b-form-group id="ref-code-chief" label-for="ref-code-chief">
                <b-form-input
                  id="ref-code-chief"
                  type="text"
                  size="md"
                  v-model="form.refCodeChief"
                  aria-describedby="ref-code-chief"
                  placeholder="Input referral code chief"
                  autocomplete="given-name"
                  @input="handleClearError('referral_code_chief')"
                  :class="{
                    'is-invalid':
                      $v.form.refCodeChief.$error ||
                      errors.messageInputs.referral_code_chief
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.refCodeChief.required &&
                      $v.form.refCodeChief.$dirty
                  "
                  >Referral Code Chief cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs &&
                      errors.messageInputs.referral_code_chief
                  "
                >
                  {{ errors.messageInputs.referral_code_chief }}
                </span>
              </b-form-group>
            </b-col>
            <!-- Level Up Date -->
            <b-col class="mb-4" sm="12">
              <h6>Level Up Start Date</h6>
              <b-form-group
                class="wrapper-input"
                id="level-up-date"
                label-for="level-up-date"
              >
                <b-form-input
                  type="text"
                  name="levelupdate"
                  v-model="form.levelUpdate"
                >
                </b-form-input>
                <!-- <div
                  class="button-clear-date"
                  v-show="form.levelUpdate"
                  @click="handleClearDate('levelup')"
                >
                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                </div> -->
              </b-form-group>
            </b-col>
            <!-- Maintenance Date -->
            <b-col class="mb-4" sm="12" v-show="form.tierId >= 2">
              <h6>Maintenance Start Date</h6>
              <b-form-group
                class="wrapper-input"
                id="level-up-date"
                label-for="level-up-date"
              >
                <b-form-input
                  type="text"
                  name="maintenancedate"
                  v-model="form.maintenance"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary">
            Submit
          </b-button>
        </b-card>
      </b-form>
      <b-modal
        id="modal-center"
        v-if="this.modal"
        size="sm"
        v-model="modal"
        @ok="handleModal"
        centered
        hide-header
        hide-footer
        style="width: 340px;"
      >
        <b-row class="p-4">
          <b-col
            class="d-flex align-items-center justify-content-center flex-column"
          >
            <div class="text-center mb-4">
              <h3 class="mb-2">Are You Sure?</h3>
              <p>Make sure the data is correct</p>
            </div>
            <div class="button-actions d-flex" style="gap: 8px;">
              <b-button
                variant="outline-secondary"
                style="width: 113px; height: 38px; color: #3B353C;"
                @click="handleModal()"
                >Back</b-button
              >
              <b-button
                variant="primary"
                style="width: 113px; height: 38px;"
                @click="onSubmit()"
                >Confirm</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const validEmail = helpers.regex("validateEmail", emailRegex);

// let levelUpDate;
// let maintenance;

export default {
  name: "edit-user-army",
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      refCode: {
        required
      },
      emailChief: {
        required,
        validEmail
      },
      refCodeChief: {
        required
      }
    }
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    }
  },
  data() {
    return {
      data: {},
      startDate: "",
      endDate: "",
      form: {
        email: "",
        refCode: "",
        title: "",
        registerDate: "",
        emailChief: "",
        refCodeChief: "",
        levelUpDate: "",
        maintenance: "",
        tierId: null
      },
      isLoading: false,
      warning_message: true,
      errors: {
        code: "",
        message: "",
        messageInputs: "",
        status: ""
      },
      modal: false
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    this.onFetchDetail();
  },
  methods: {
    async onFetchDetail() {
      this.isLoading = true;
      await this.$http
        .get(`army/users/${atob(this.$route.params.id)}`)
        .then(res => {
          this.isLoading = false;
          const data = res?.data?.data;

          this.form = {
            email: data?.email_user,
            refCode: data?.referral_code_user,
            title: data?.title,
            registerDate: data?.created_at,
            emailChief: data?.email_chief,
            refCodeChief: data?.referral_code_chief,
            levelUpDate: data?.promote_date,
            maintenance: data?.maintenance_date,
            tierId: data?.tier_id
          };
        })
        .catch(error => {
          const err = error?.response;
          if (err) {
            this.errors.code = err.status;
            this.errors.message = err.data.meta.message;
            this.errors.status = err.data.meta.code;
          }
        });
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    onSubmit() {
      this.isLoading = true;
      this.handleModal();

      const requestBody = {
        referral_code_user: this.form.refCode,
        email_chief: this.form.emailChief,
        referral_code_chief: this.form.refCodeChief,
        promote_date: this.form.levelUpDate
          ? moment(this.form.levelUpDate).format("YYYY-MM-DD")
          : this.form.levelUpDate,
        maintenance_date: this.form.maintenance
          ? moment(this.form.maintenance).format("YYYY-MM-DD")
          : this.form.maintenance
      };

      this.$http
        .post(`army/users/${atob(this.$route.params.id)}`, requestBody)
        .then(res => {
          this.isLoading = false;
          this.$swal
            .fire("Success!", `${res.data.meta.message}`, "success")
            .then(() => {
              this.$router.push({ name: "User Army" });
            });
        })
        .catch(error => {
          const err = error.response;

          if (err) {
            this.isLoading = false;
            this.errors.code = err.status;
            this.errors.message = err.data.meta.message;
            this.errors.messageInputs = err.data.data;
            this.errors.status = err.data.meta.status;

            if (this.errors.status === 404) {
              this.$swal.fire("Failed!", `${res.data.meta.message}`, "error");
            } else {
              this.$swal.fire("Failed!", this.errors.message, "error");
            }
          }
        });
    },
    handleModal() {
      this.modal = !this.modal;
    },
    handleClearDate(val) {
      if (val == "levelup") {
        this.form.levelUpDate = "";
      } else {
        this.form.maintenance = "";
      }
    },
    handlePreSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) this.handleModal();
    }
  },
  mounted() {
    const onDateSelected = (start, end, inputName) => {
      const newValue = start.format("YYYY-MM-DD");

      if (inputName === "levelupdate") {
        this.form.levelUpDate = newValue;
      } else if (inputName === "maintenancedate") {
        this.form.maintenance = newValue;
      }

      $(`input[name="${inputName}"]`).data(
        "daterangepicker"
      ).autoUpdateInput = true;
    };

    const fetch = async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await this.$http.get(
          `army/users/${atob(this.$route.params.id)}`
        );
        return response?.data?.data;
      } catch (error) {
        throw error;
      }
    };

    $(async function() {
      const res = await fetch();

      const datepickerOptions = {
        singleDatePicker: true,
        alwaysShowCalendars: true,
        autoApply: true,
        readonly: true,
        drops: "auto",
        locale: {
          format: "YYYY-MM-DD"
        }
      };

      const levelUpdateDatepicker = $('input[name="levelupdate"]');
      const maintenanceDateDatepicker = $('input[name="maintenancedate"]');

      // Level Up Datepicker
      if (res?.promote_date) {
        const newDate = res?.promote_date;
        levelUpdateDatepicker.val(moment(newDate).format("YYYY-MM-DD"));
      }

      levelUpdateDatepicker.daterangepicker(
        {
          ...datepickerOptions,
          autoUpdateInput: !!res?.promote_date
        },
        function(start, end) {
          onDateSelected(start, end, "levelupdate");
        }
      );

      // Maintenance Datepicker
      if (res?.maintenance_date) {
        const newDate = res?.maintenance_date;
        maintenanceDateDatepicker.val(moment(newDate).format("YYYY-MM-DD"));
      }

      maintenanceDateDatepicker.daterangepicker(
        {
          ...datepickerOptions,
          autoUpdateInput: !!res?.maintenance_date
        },
        function(start, end) {
          onDateSelected(start, end, "maintenancedate");
        }
      );
    });
  }
};
</script>

<style>
.submt {
  width: 100%;
  height: auto;
  display: block;
}
</style>
<style>
.wrapper-input {
  position: relative;
}

.button-clear-date {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.button-clear-date:hover {
  color: #000;
}

.btn.disabled {
  background: #dfdfdf !important;
  color: grey !important;
  border-color: #dfdfdf !important;
}

.form-control {
  height: 36.22px;
}

.text-red {
  color: #cb211f;
}
.text-success {
  color: #35aa4b;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
